import React, { useState } from 'react';
import { db, collection, addDoc, analytics } from './firebase';
import './App.css';
import logo from "./logosline2.png"
import { logEvent } from "firebase/analytics";
import DOMPurify from 'dompurify';

function App() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('Form submitted');

    // Validate email and consent
    if (!isValidEmail(email)) {
      setMessage('Invalid email address.');
      setLoading(false);
      return;
    }

    if (!consent) {
      setMessage('You must agree to the privacy policy.');
      setLoading(false);
      return;
    }

    // Sanitize email to prevent XSS attacks
    const sanitizedEmail = DOMPurify.sanitize(email);

    try {
      await addDoc(collection(db, 'waitlist'), { email: sanitizedEmail });
      logEvent(analytics, 'sign_up', { email: sanitizedEmail });
      setMessage('Thank you for signing up!');
      setEmail('');
      setConsent(false);
    } catch (error) {
      console.error('Error adding document: ', error);
      setMessage('Something went wrong, please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="App">
      {showPrivacyPolicy ? (
        <div className='privacy-policy'>
           <h1>Privacy Policy</h1>
      <p>We respect your privacy and are committed to protecting your personal data. This policy outlines how we collect, use, and protect your personal information.</p>
      
      <h2>1. What data do we collect?</h2>
      <p><strong>Email Address:</strong> We collect your email address when you sign up for our waiting list.</p>
    
      
      <h2>2. How do we use your data?</h2>
      <p>Your personal data is used for the following purposes:</p>
      <p><strong>Notifications:</strong> To notify you about updates regarding our service.</p>
     
      <p>We will not share your email address with third parties without your consent, except as required by law.</p>
      
      <h2>3. How do we protect your data?</h2>
      <p>We implement appropriate security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. These measures include:</p>
      <p><strong>Data Encryption:</strong> We use industry-standard encryption to protect your data during transmission and storage.</p>
      <p><strong>Access Controls:</strong> We restrict access to your personal data to authorized personnel only.</p>
      <p><strong>Regular Security Audits:</strong> We conduct regular security audits to identify and address potential vulnerabilities.</p>
     

      <h2>4. Your rights</h2>
      <p>You have the following rights regarding your personal data:</p>
      <p><strong>Access:</strong> You have the right to request access to your personal data.</p>
      <p><strong>Correction:</strong> You have the right to request correction of any inaccurate or incomplete personal data.</p>
      <p><strong>Deletion:</strong> You have the right to request deletion of your personal data, subject to certain exceptions.</p>
      <p><strong>Objection:</strong> You have the right to object to the processing of your personal data for certain purposes.</p>
      <p><strong>Restriction:</strong> You have the right to request the restriction of processing of your personal data under certain circumstances.</p>
      <p><strong>Data Portability:</strong> You have the right to request the transfer of your personal data to another organization.</p>
      <p>To exercise any of these rights, please contact us at <strong>info@commendo.ai</strong>.</p>
      
      <h2>5. Changes to this privacy policy</h2>
      <p>We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website.</p>
      
      <h2>6. Contact us</h2>
      <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at:</p>
      <p><strong>Email:</strong> info@commendo.ai</p>
          <button onClick={() => setShowPrivacyPolicy(false)}>Back</button>
        </div>
      ) : (
        <div>
          <img src={logo} alt="Commendo.ai Logo" className="logo" />
          <h1>Commendo.ai</h1>
          <h2>Your Shopping Assistant</h2>
          <p>Join the waiting list!</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="form-group">
              <input
                type="checkbox"
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
              />
              <label>
                I agree to the <button type="button" onClick={() => setShowPrivacyPolicy(true)}>privacy policy</button>.
              </label>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          {message && <p className={message.includes('Thank you') ? 'success' : 'error'}>{DOMPurify.sanitize(message)}</p>}
        </div>
      )}
    </div>
  );
}

export default App;